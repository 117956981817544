import React from "react";
import classes from "./ProfileLimitReached.module.scss";
import {MdOutlineClose} from "react-icons/md";
import {GiPadlock} from "react-icons/gi";
import {createGradient} from "../../../shared/Constants";

const ProfileLimitReached = (props) => {
  return (
    <div className={classes.ProfileLimitReached}>
      <div className={classes.CloseButtonContainer}>
        <div onClick={props.onClose}>
          <MdOutlineClose size={22} color="#C5C5C5"/>
        </div>
      </div>
      <GiPadlock size={60} color={props.color}/>
      <p className={classes.MainText}>
        You have reached<br/>the max no. of profiles allowed.
      </p>
      <p className={classes.InfoText}>Please contact Commercial
        Prime<br/>to add more!</p>
      <button
        style={{
          background: `linear-gradient(
                142deg,
                ${props.color} 0%,
                ${createGradient(props.color, 50)} 100%`
        }}
        className={classes.GotItButton}
        onClick={() => window.open('https://commercialprime.co.uk', '_blank')}
      >
        Got It!
      </button>
    </div>
  );
};

export default ProfileLimitReached;
