import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(config => {
    const authData = JSON.parse(localStorage.getItem('prime'));

    if (authData) {
        config.headers['Authorization'] = `Bearer ${authData.token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.status === 401) {
        localStorage.removeItem('prime');
        window.location.href = '/';
    }
    return Promise.reject(error);
});

export default axiosInstance;
