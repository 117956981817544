import axios from '../../../axios';
import * as actionTypes from './actionTypes';

const getAllProfilesStart = () => {
  return {
    type: actionTypes.GET_ALL_PROFILES_START,
  };
};

const getAllProfilesSuccess = payload => {
  return {
    type: actionTypes.GET_ALL_PROFILES_SUCCESS,
    payload,
  };
};

const getAllProfilesFail = () => {
  return {
    type: actionTypes.GET_ALL_PROFILES_FAIL,
  };
};

export const getAllProfiles = () => {
  return dispatch => {
    dispatch(getAllProfilesStart());
    axios
      .get('api/user-service/get-profiles')
      .then(res => {
        console.log(res.data);
        const profileData = {
          profiles: res.data,
          vcardStatus: res.data[0].accountStatus,
          themeColor: res.data[0].color,
          profileLimit: res.data[0].profileLimit,
          logo: res.data[0].logo,
          companyExits: res.data[0].companyExits,
          privacyMode: res.data[0].userPrivacyMode,
          tenantEnabledPrivacyMode: res.data[0].tenantPrivacyMode,
          companyName : res.data[0].companyName,
        };
        dispatch(getAllProfilesSuccess(profileData));
      })
      .catch(() => dispatch(getAllProfilesFail()));
  };
};

export const changePrivacyModeSuccess = (value) => {
  return {
    type: actionTypes.CHANGE_PRIVACY_MODE_SUCCESS,
    payload: {
      privacyMode: value,
    },
  };
}

export const changePrivacyMode = (value) => {
  return async (dispatch) => {
    try {
      dispatch(changePrivacyModeSuccess(value));
      const res = await axios.post('/api/user-service/privacy-mode?privacyMode=' + value);
      dispatch(changePrivacyModeSuccess(res.data));
    } catch (error) {
      console.error('Error updating privacy mode:', error);
    }
  };
};



export const changeActiveProfile = ( profileId, showToast, callback) => {
  return dispatch => {
    axios
      .post('/api/user-service/change-active-profile', { profileId})
      .then(() => {
        dispatch(getAllProfiles());
        if (showToast) {
          showToast('Active Profile Changed Successfully!');
        }
        if (callback) {
          callback();
        }
      })
      .catch(err => {
        if (!err.response) {
          if (showToast) {
            showToast('Check Your Internet Connectivity!');
          }
        } else {
          if (showToast) {
            showToast('Failed to Change Active Profile!');
          }
        }
      });
  };
};

const changeVcardStatusStart = () => {
  return {
    type: actionTypes.CHANGE_VCARD_STATUS_START,
  };
};

const changeVcardStatusSuccess = status => {
  return {
    type: actionTypes.CHANGE_VCARD_STATUS_SUCCESS,
    payload: {
      vcardStatus: status,
    },
  };
};

const changeVcardStatusFail = () => {
  return {
    type: actionTypes.CHANGE_VCARD_STATUS_FAIL,
  };
};

export const changeVcardStatus = (userId, newStatus) => {
  return dispatch => {
    dispatch(changeVcardStatusStart());
    axios
      .post('/api/user-service/change-product-status', null, {
        params: {
          userId,
          status: newStatus,
        },
      })
      .then(() => dispatch(changeVcardStatusSuccess(newStatus)))
      .catch(() => dispatch(changeVcardStatusFail()));
  };
};

export const changeThemeColor = themeColor => {
  return {
    type: actionTypes.CHANGE_THEME_COLOR,
    themeColor,
  };
};
