import React from 'react';
import classes from './DashboardHeader.module.scss';
import {FaPowerOff} from 'react-icons/fa';
import Logo from '../../../assets/images/commercial prime-33.svg';
import MiniLogo from '../../../assets/images/commercial-prime-logo-svg.svg';
import {HiMenuAlt2} from 'react-icons/hi';
import {BiDotsVerticalRounded} from 'react-icons/bi';
import {useLocation, useNavigate} from 'react-router-dom';
import Backdrop from '../../UI/Backdrop/Backdrop';
import StatusChangeConfirmation from '../DashboardBody/Home/StatusChangeConfirmation/StatusChangeConfirmation';
import LogOutConfirmation from "./LogOutConfirmation/LogOutConfirmation";
import {connect} from 'react-redux';
import {ClipLoader} from 'react-spinners';
import SVG from 'react-inlinesvg';
import {HiQrcode} from "react-icons/hi";
import QRCodePopup from "../DashboardBody/Home/QRCodePopup/QRCodePopup";
import {Switch} from "antd";
import {BiSolidUserRectangle} from "react-icons/bi";
import PrivacyMode from "../../../assets/images/highPrivacy.png";
import {changePrivacyMode} from "../../../store/actions";
import PrivacyModeChangeConfirm from "../../UI/PrivacyModeChangeConfirm/PrivacyModeChangeConfirm";

const DashboardHeader = props => {
  const location = useLocation();
  const navigate = useNavigate();

  const [activationStatusPopupVisible, setActivationStatusPopupVisibility] =
    React.useState(false);

  const [qrCodePopupVisible, setQrCodePopupVisibility] = React.useState(false);

  const [privacyModePopupVisible, setPrivacyModePopupVisibility] =React.useState(false);

  const [logoutConfirmationPopupVisible, setLogoutConfirmationPopupVisible] = React.useState(false);

  const dashboardHeaderStyles = [classes.DashboardHeader];

  if (
    location.pathname === '/home/contacts' ||
    location.pathname === '/home/products' ||
    location.pathname === '/home/account' ||
    location.pathname === '/home/analytics' ||
    location.pathname.includes('/home/teams')
  ) {
    dashboardHeaderStyles.push(classes.DashboardHeaderHidden);
  }

  if (!props.tenantEnabledPrivacyMode) {
    dashboardHeaderStyles.push(classes.TenantModeHeader)
  }


  const handleSwitchClick = () => {
    props.onChangeHighPrivacyMode(!props.privacyMode);
    setPrivacyModePopupVisibility(false)
  };

  return (
    <div className={dashboardHeaderStyles.join(' ')}>
      {activationStatusPopupVisible ? (
        <Backdrop onClick={() => setActivationStatusPopupVisibility(false)}>
          <StatusChangeConfirmation
            onClose={() => setActivationStatusPopupVisibility(false)}
          />
        </Backdrop>
      ) : null}

      {privacyModePopupVisible ? (
        <Backdrop onClick={() => setPrivacyModePopupVisibility(false)}>
          <PrivacyModeChangeConfirm
            onClose={() => setPrivacyModePopupVisibility(false)}
            color={props.color}
            active={props.privacyMode}
            onClick={handleSwitchClick}
          />
        </Backdrop>
      ) : null}

      {logoutConfirmationPopupVisible ? (
        <Backdrop onClick={() => setLogoutConfirmationPopupVisible(false)}>
          <LogOutConfirmation
            color={props.color}
            onClose={() => setLogoutConfirmationPopupVisible(false)}
          />
        </Backdrop>
      ) : null}

      {qrCodePopupVisible ? (
        <Backdrop onClick={() => setQrCodePopupVisibility(false)}>
          <QRCodePopup
            onClose={() => setQrCodePopupVisibility(false)}
            color={props.color}
          />
        </Backdrop>
      ) : null}

      <div className={classes.HeaderTop}>
        {location.pathname === '/home/contacts' ? (
          <div
            className={classes.SideButtonContainer}
            onClick={props.toggleDropdownVisibility}
          >
            <BiDotsVerticalRounded size={24} color="#BCBCBC"/>
          </div>
        ) : location.pathname === '/home' ? (
          <>
            {props.collaboration ? (
              <div className={classes.CollaborationWrapper}>
                <SVG fill={props.color} className={classes.Logo} src={Logo} alt={'Commercial Prime'}/>
                <p>|</p>
                <img src={props.logo} alt={'logo'} className={classes.Logo}/>
              </div>
            ) : (
              props.logo === '' || props.logo === null ? (
                <SVG fill={props.color} className={classes.Logo} src={Logo} alt={'Commercial Prime'}/>
              ) : (
                <img src={props.logo} alt={'logo'} className={classes.Logo}/>
              )
            )}


            <FaPowerOff
              id="log-out-tooltip"
              className={classes.Logout}
              size={20}
              onClick={() => {
                setLogoutConfirmationPopupVisible(true)
              }}
            />
          </>
        ) : null}
        <p className={classes.PageTitle}>
          {location.pathname === '/home/contacts'
            ? 'My Contacts'
            : location.pathname === '/home/products'
              ? 'Shareable Link'
              : location.pathname === '/home/account'
                ? 'Account'
                : location.pathname === '/home/analytics'
                  ? 'Analytics'
                  : location.pathname.includes('/home/teams')
                    ? 'Teams'
                    : ''}
        </p>
        {location.pathname === '/home/contacts' ||
        location.pathname === '/home/products' ||
        location.pathname === '/home/analytics' ||
        location.pathname === '/home/account' ||
        location.pathname.includes('/home/teams') ? (
          <SVG
            src={MiniLogo}
            alt={process.env.REACT_APP_SHARABLE_LINK}
            className={classes.MiniLogo}
            fill={props.color}
          />
        ) : null}

        <div className={classes.HamburgerContainer} onClick={props.toggleMenu}>
          Menu
          <HiMenuAlt2 size={24} color="#BCBCBC"/>
        </div>
      </div>
      <div className={classes.HeaderBottom}>
        <div className={classes.ButtonWrapper}>
          <div className={classes.Greeting}>
            <h1>Hello,</h1>
            <span style={{color: props.color}}>{props.user}!</span>
          </div>
          <div className={classes.QrContainer}>
            <HiQrcode className={classes.QrButton} onClick={() => setQrCodePopupVisibility(true)}/>
            {props.changingVcardStatus ? (
              <div className={classes.SpinnerContainer}>
                <ClipLoader color={'#dbb659'} loading={true} size={20}/>
              </div>
            ) : (
              <div
                className={
                  props.vcardStatus === 'ACTIVE'
                    ? classes.AccountStatusActive
                    : classes.AccountStatusInactive
                }
                onClick={() => setActivationStatusPopupVisibility(true)}
              >
                <div>
              <span>
                {props.vcardStatus === 'ACTIVE'
                  ? 'Card Active'
                  : 'Card Inactive'}
              </span>
                </div>
              </div>
            )}
          </div>
        </div>

        {props.tenantEnabledPrivacyMode &&
          <div className={classes.ButtonWrapper}>
            <div className={classes.HighPrivacySwitch}>
              <img src={PrivacyMode} alt={"privacy mode"}/>
              <p>High Privacy <br/>Mode</p>
              <Switch
                checked={props.privacyMode}
                checkedChildren="on"
                unCheckedChildren="off"
                //onClick={handleSwitchClick}
                onClick={()=>setPrivacyModePopupVisibility(true)}
              />
            </div>
            <button
              style={{borderBottomColor: props.color}}
              className={classes.MyContactButton}
              onClick={() => navigate('/home/contacts')}
            >
              <BiSolidUserRectangle size={15}/>
              My Contacts
            </button>
          </div>
        }

      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    vcardStatus: state.home.vcardStatus,
    changingVcardStatus: state.home.changingVcardStatus,
    color: state.home.themeColor,
    logo: state.home.logo,
    collaboration: state.home.collaboration,
    tenantEnabledPrivacyMode: state.home.tenantEnabledPrivacyMode,
    privacyMode: state.home.privacyMode,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onChangeHighPrivacyMode: value => dispatch(changePrivacyMode(value)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
