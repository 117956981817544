import axios from '../../../axios';
import {VISIBILITY} from '../../../shared/Constants';
import * as actionTypes from './actionTypes';

export const clearData = () => {
  return {
    type: actionTypes.CLEAR_DATA,
  };
};

export const addProfileField = (icon, fieldName, fieldContent) => {
  return {
    type: actionTypes.ADD_PROFILE_FIELD,
    payload: {
      icon,
      fieldName,
      fieldContent,
    },
  };
};

export const saveProfilePicture = profile => {
  return {
    type: actionTypes.SAVE_PROFILE_PICTURE_SUCCESS,
    profile,
  };
};

export const changeProfilePicture = image => {
  return {
    type: actionTypes.CHANGE_PROFILE_PICTURE,
    image,
  };
};

export const changeProfileName = name => {
  return {
    type: actionTypes.CHANGE_PROFILE_NAME,
    name,
  };
};

export const changeLeftProfileField = (field, fieldIndex) => {
  return {
    type: actionTypes.CHANGE_LEFT_PROFILE_FIELD,
    field,
    fieldIndex,
  };
};

export const changeRightProfileField = (field, fieldIndex) => {
  return {
    type: actionTypes.CHANGE_RIGHT_PROFIE_FIELD,
    field,
    fieldIndex,
  };
};

export const changeAdditionalProfileField = (field, fieldIndex) => {
  return {
    type: actionTypes.CHANGE_ADDITIONAL_PROFILE_FIELD,
    field,
    fieldIndex,
  };
};

export const removeAdditionalProfileField = fieldIndex => {
  return {
    type: actionTypes.REMOVE_ADDITIONAL_PROFILE_FIELD,
    fieldIndex,
  };
};

export const uploadProfilePicture = image => {
  return async (dispatch) => {
    const token = JSON.parse(localStorage.getItem('prime'));

    const myHeaders = new Headers();
    myHeaders.append(
      'Authorization',
      'Bearer ' + token.token,
    );

    const formData = new FormData();
    formData.append('file', image);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/user-service/uploadFile`,
        requestOptions,
      );
      const result = await response.text();

      dispatch(changeProfilePicture(result));
      return result;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };
};


export const saveProfile = (requestData, successCallback) => {
  return dispatch => {
    axios
      .post('/api/user-service/create-profile', requestData)
      .then(() => {
        // dispatch(clearData());
        successCallback();
      })
      .catch(err => console.log(err.response.data));
  };
};

export const updateProfile = (requestData, successCallback) => {
  return dispatch => {
    axios
      .post('/api/user-service/update-profile', requestData)
      .then(() => {
        // dispatch(clearData());
        successCallback();
      })
      .catch(err => console.log(err.response.data));
  };
};

export const deleteProfile = (profileId, successCallback, showToast) => {
  return dispatch => {
    axios
      .delete(`/api/user-service/delete-profile?profileId=${profileId}`)
      .then(() => {
        if (successCallback) successCallback();
      })
      .catch(err => {
        showToast('Cannot delete active profile')
        console.log(err)
      });
  };
};

export const setProfileData = payload => {
  return {
    type: actionTypes.SET_PROFILE_DATA,
    payload,
  };
};

export const selectProfile = (endPoint, profileId) => {
  return dispatch => {
    axios
      .get(endPoint, {params: {profileId}})
      .then(res => {

        const darkMode = res.data.darkMode;

        let profilePicture = {
          id: null,
          name: 'Profile Picture',
          icon: '',
          value: '',
          visibility: VISIBILITY.PUBLIC,
        };

        const profileName = res.data.name ? res.data.name : '';

        const leftProfileFields = [];
        const rightProfileFields = [];
        const additionalProfileFields = [];

        const leftFieldNames = {
          'First Name': {icon: 'FaUser'},
          'Last Name': {icon: 'FaUser'},
          'Company': {icon: 'FaBriefcase'},
          'Title': {icon: 'FaAnchor'},
          'Contact Number': {icon: 'FaPhone'},
          'Email': {icon: 'FaEnvelope'},
          'Description': {icon: 'FaBolt'},
        };

        const rightFieldNames = {
          'Website': {icon: 'FaLink'},
          'WhatsApp': {icon: 'FaWhatsapp'},
          'Facebook': {icon: 'FaFacebook'},
          'Instagram': {icon: 'FaInstagram'},
          'LinkedIn': {icon: 'FaLinkedin'},
          'X': {icon: 'FaXTwitter'},
        };

        const profilePictureObj = res.data.data.find(
          field => field.name === 'Profile Picture',
        );

        if (profilePictureObj) {
          profilePicture = {
            ...profilePictureObj,
            visibility: profilePictureObj.publicVisible
              ? VISIBILITY.PUBLIC
              : VISIBILITY.PRIVATE,
          };
        }

        Object.keys(leftFieldNames).forEach(name => {
          const fieldObj = res.data.data.find(field => field.name === name);

          if (fieldObj) {
            leftProfileFields.push({
              ...fieldObj,
              visibility: fieldObj.publicVisible
                ? VISIBILITY.PUBLIC
                : VISIBILITY.PRIVATE,
            });
          }
        });

        Object.keys(rightFieldNames).forEach(name => {
          const fieldObj = res.data.data.find(field => field.name === name);

          if (fieldObj) {
            rightProfileFields.push({
              ...fieldObj,
              visibility: fieldObj.publicVisible
                ? VISIBILITY.PUBLIC
                : VISIBILITY.PRIVATE,
            });
          }
        });

        res.data.data.forEach(field => {
          const alreadyInLeft = Object.keys(leftFieldNames).find(
            name => field.name === name,
          );
          const alreadyInRight = Object.keys(rightFieldNames).find(
            name => field.name === name,
          );

          if (
            !alreadyInLeft &&
            !alreadyInRight &&
            field.name !== 'Profile Picture'
          ) {
            additionalProfileFields.push({
              ...field,
              visibility: field.publicVisible
                ? VISIBILITY.PUBLIC
                : VISIBILITY.PRIVATE,
            });
          }
        });

        dispatch(
          setProfileData({
            profilePicture,
            profileName,
            leftProfileFields,
            rightProfileFields,
            additionalProfileFields,
            darkMode,
          }),
        );
      })
      .catch(err => console.log('error :' + err));
  };
};

export const changeDarkTheme = () => {
  return {
    type: actionTypes.CHANGE_DARK_MODE,
  };
}
