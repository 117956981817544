import * as actionTypes from '../actions/products/actionTypes';

const initialState = {
    products: [],
};

const getProductsByUsernameSuccess = (state, action) => {
    return {
        ...state,
        products: action.products,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PRODUCTS_BY_USERNAME_SUCCESS:
            return getProductsByUsernameSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
