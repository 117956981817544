import {VISIBILITY} from '../../shared/Constants';
import * as actionTypes from '../actions/profile/actionTypes';

const initialState = {

  profilePicture: {
    id: null,
    name: 'Profile Picture',
    icon: '',
    value: '',
    visibility: VISIBILITY.PUBLIC,
  },

  profileName: '',
  pictureLink: '',

  leftProfileFields: [
    {
      id: null,
      name: 'First Name',
      icon: 'FaUser',
      value: '',
      visibility: VISIBILITY.PUBLIC,
    },
    {
      id: null,
      name: 'Last Name',
      icon: 'FaUser',
      value: '',
      visibility: VISIBILITY.PUBLIC,
    },

    {
      id: null,
      name: 'Company',
      icon: 'FaBriefcase',
      value: '',
      visibility: VISIBILITY.PUBLIC,
    },
    {
      id: null,
      name: 'Title',
      icon: 'FaAnchor',
      value: '',
      visibility: VISIBILITY.PUBLIC,
    },
    {
      id: null,
      name: 'Contact Number',
      icon: 'FaPhone',
      value: '',
      visibility: VISIBILITY.PUBLIC,
    },
    {
      id: null,
      name: 'Email',
      icon: 'FaEnvelope',
      value: '',
      visibility: VISIBILITY.PUBLIC,
    },
    {
      id: null,
      name: 'Description',
      icon: 'FaBolt',
      value: '',
      visibility: VISIBILITY.PUBLIC,
    },
  ],
  rightProfileFields: [
    {
      id: null,
      name: 'Website',
      icon: 'FaLink',
      value: '',
      visibility: VISIBILITY.PUBLIC,
    },
    {
      id: null,
      name: 'WhatsApp',
      icon: 'FaWhatsapp',
      value: '',
      visibility: VISIBILITY.PUBLIC,
    },
    {
      id: null,
      name: 'Facebook',
      icon: 'FaFacebook',
      value: '',
      visibility: VISIBILITY.PUBLIC,
    },
    {
      id: null,
      name: 'Instagram',
      icon: 'FaInstagram',
      value: '',
      visibility: VISIBILITY.PUBLIC,
    },
    {
      id: null,
      name: 'LinkedIn',
      icon: 'FaLinkedin',
      value: '',
      visibility: VISIBILITY.PUBLIC,
    },
    {
      id: null,
      name: 'X',
      icon: 'FaXTwitter',
      value: '',
      visibility: VISIBILITY.PUBLIC,
    },
  ],
  additionalProfileFields: [],
  darkMode: true,
};

const clearData = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

const saveProfilePicture = (state, action) => {
  return {...state,pictureLink: action.profile}
}

const addProfileField = (state, action) => {
  const updatedAdditionalProfileFields = [...state.additionalProfileFields];

  updatedAdditionalProfileFields.push({
    id: null,
    name: action.payload.fieldName,
    icon: action.payload.icon,
    value: action.payload.fieldContent,
    visibility: VISIBILITY.PUBLIC,
  });

  return {
    ...state,
    additionalProfileFields: updatedAdditionalProfileFields,
  };
};

const changeProfilePicture = (state, action) => {
  return {
    ...state,
    profilePicture: {...state.profilePicture, value: action.image},
  };
};

const changeProfileName = (state, action) => {
  return {
    ...state,
    profileName: action.name,
  };
};

const changeLeftProfileField = (state, action) => {
  const updatedLeftProfileFields = [...state.leftProfileFields];

  updatedLeftProfileFields[action.fieldIndex] = action.field;

  return {
    ...state,
    leftProfileFields: updatedLeftProfileFields,
  };
};

const changeRightProfileField = (state, action) => {
  const updatedRightProfileFields = [...state.rightProfileFields];

  updatedRightProfileFields[action.fieldIndex] = action.field;

  return {
    ...state,
    rightProfileFields: updatedRightProfileFields,
  };
};

const changeAdditionalProfileField = (state, action) => {
  const updatedAdditionalProfileFields = [...state.additionalProfileFields];

  updatedAdditionalProfileFields[action.fieldIndex] = action.field;

  return {
    ...state,
    additionalProfileFields: updatedAdditionalProfileFields,
  };
};

const removeAdditionalProfileField = (state, action) => {
  let updatedAdditionalProfileFields = [...state.additionalProfileFields];

  updatedAdditionalProfileFields = updatedAdditionalProfileFields.filter(
    (field, index) => index !== action.fieldIndex,
  );

  return {
    ...state,
    additionalProfileFields: updatedAdditionalProfileFields,
  };
};

const setProfileData = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const changeDarkMode = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_DATA:
      return clearData(state, action);
    case actionTypes.CHANGE_DARK_MODE:
      return changeDarkMode(state, { payload: { darkMode: !state.darkMode } });
    case actionTypes.SAVE_PROFILE_PICTURE_SUCCESS:
      return saveProfilePicture(state, action);
    case actionTypes.ADD_PROFILE_FIELD:
      return addProfileField(state, action);
    case actionTypes.CHANGE_PROFILE_PICTURE:
      return changeProfilePicture(state, action);
    case actionTypes.CHANGE_PROFILE_NAME:
      return changeProfileName(state, action);
    case actionTypes.CHANGE_LEFT_PROFILE_FIELD:
      return changeLeftProfileField(state, action);
    case actionTypes.CHANGE_RIGHT_PROFIE_FIELD:
      return changeRightProfileField(state, action);
    case actionTypes.CHANGE_ADDITIONAL_PROFILE_FIELD:
      return changeAdditionalProfileField(state, action);
    case actionTypes.REMOVE_ADDITIONAL_PROFILE_FIELD:
      return removeAdditionalProfileField(state, action);
    case actionTypes.SET_PROFILE_DATA:
      return setProfileData(state, action);

    default:
      return state;
  }
};

export default reducer;
